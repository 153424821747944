import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: [1],
    questionExclusions: [] // Your initial question exclusions
};

export const questionTypesSlice = createSlice({
    name: 'questionTypes',
    initialState,
    reducers: {
        setSelectedQuestionTypes: (state, action) => {
            state.value = action.payload;
        },
        setQuestionExclusions: (state, action) => {
            state.questionExclusions = action.payload; // Update questionExclusions directly
        }
    },
});

export const { setSelectedQuestionTypes, setQuestionExclusions } = questionTypesSlice.actions;
export default questionTypesSlice.reducer;
