// DashboardSection.js

import React, { useEffect, useRef } from "react";
import "./DashboardSection.css";
import DashboardImage1 from "../../assets/img/DashboardImage1.png";
import PeakingOwl from "../../assets/img/peakingOwl.svg"; // Your SVG
import myLottieAnimation1 from "../../assets/lottieAnimations/squigglyLine.json";
import Lottie from "lottie-web";

const DashboardSection = () => {
  const lottieRef = useRef(null);

  useEffect(() => {
    const instance = Lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: myLottieAnimation1,
    });

    instance.setSpeed(0.5);

    return () => instance.destroy();
  }, []);
  return (
    <div className="dashboard-container">
      <h2 className="dashboard-title">University Tailored</h2>
      <h3 className="dashboard-subtitle">Medical & Dental Question Banks</h3>
      <div className="image-slider">
        <img
          src={DashboardImage1}
          alt="Dashboard Interface"
          className="dashboard-image"
        />
        <img src={PeakingOwl} alt="Peaking Owl" className="peaking-owl" />
      </div>
      <div
        ref={lottieRef}
        className="lottie-animation"
        style={{ height: 200 }}
      ></div>
      {/* <p className="dashboard-release">
        Coming <span className="blue-text">April 2024</span>
      </p> */}
    </div>
  );
};

export default DashboardSection;
