import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import selectedSubcategoriesReducer from "./slices/selectedSubcategoriesSlice";
import selectedQuestionsReducer from "./slices/selectedQuestionsSlice";
import questionTypesReducer from "./slices/questionTypesReducer"

/** call reducers */

import questionReducer from "./reducers/question_reducer";
import resultReducer from "./reducers/result_reducer";

const rootReducer = combineReducers({
  auth: authSlice,
  questions: questionReducer,
  result: resultReducer,
  selectedSubcategories: selectedSubcategoriesReducer,
    selectedQuestions: selectedQuestionsReducer,
    type: questionTypesReducer,
});

const store = configureStore({ reducer: rootReducer });

/** Create store with reducer */
export default store;
