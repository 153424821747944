import React, { useState, useEffect } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../DashboardQuestionInterfaceStyles/DashboardReviewCard.css";
import FlagIcon from "@mui/icons-material/Flag";

const DashboardReviewCard = ({
  trace,
  isSubmitted,
  isCorrect,
  onToggle,
  onQuestionClick,
  flags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [questionsReviewed, setQuestionsReviewed] = useState([]);

  useEffect(() => {
    if (!questionsReviewed.includes(trace + 1)) {
      setQuestionsReviewed([...questionsReviewed, trace + 1]);
    }
  }, [trace, questionsReviewed]);

  const toggleDrawer = () => {
    const nextState = !isOpen;
    setIsOpen(nextState);
    onToggle(nextState); // Inform parent of the state change
  };

  const totalSubmitted = isSubmitted.filter((sub) => sub).length;
  const totalCorrect = isCorrect.filter(
    (correct, index) => correct && isSubmitted[index]
  ).length;
  const percentage = totalSubmitted ? (totalCorrect / totalSubmitted) * 100 : 0;

  return (
    <div className={`review-card ${isOpen ? "open" : "closed"}`}>
      <button className="toggle-button" onClick={toggleDrawer}>
        {isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </button>
      <h4>Review Card</h4>
      <div className="questions-list">
        {questionsReviewed.map((questionNumber) => (
          <div
            key={questionNumber}
            className="question-item"
            onClick={() => onQuestionClick(questionNumber)}
          >
            <span className="question-number">{questionNumber}</span>
            <div className="icon-container">
              {flags[questionNumber - 1] && <span className="tick">🚩</span>}
              {isSubmitted[questionNumber - 1] &&
                (isCorrect[questionNumber - 1] ? (
                  <span className="tick">✅</span>
                ) : (
                  <span className="cross">❌</span>
                ))}
            </div>
          </div>
        ))}
      </div>
      <div className="percentage-display">
        <div className="percentage-value">
          {totalSubmitted
            ? `${percentage.toFixed(2)}%`
            : "No questions submitted"}
        </div>
      </div>
    </div>
  );
};

export default DashboardReviewCard;
