import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaEarlybirds } from "react-icons/fa";
import { ReactComponent as Logo } from "../../assets/img/realOwlLogo.svg";
import { FaBars, FaTimes } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { useSelector } from "react-redux";

function Navbar() {
    const { isAuth } = useSelector((state) => state.auth);
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const navbar = document.querySelector(".navbar");
            if (!navbar) return; // Add this line for the safety check
            if (window.scrollY >= 80) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
        window.addEventListener("resize", showButton);
        return () => {
            window.removeEventListener("resize", showButton);
        };
    }, []);

    return (
        <>
            <IconContext.Provider value={{ color: "#000000" }}>
                <nav className={scrolled ? "navbar scroll-navbar" : "navbar"}>
                    <div className="navbar-container container">
                        <Link to="/" className="navbar-logo" onClick={closeMobileMenu}>
                            <Logo className="navbar-icon" />
                            <span className="brand-name">Preclinique</span>
                        </Link>

                        <div className="menu-icon" onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </div>
                        <ul className={click ? "nav-menu active" : "nav-menu"}>
                            <li className="nav-item">
                                <Link
                                    to="/whats-new"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    What's new?
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/contact"
                                    className="nav-links"
                                    onClick={closeMobileMenu}
                                >
                                    Contact
                                </Link>
                            </li>

                            {isAuth ? (
                                <Link to="/dashboard" />
                            ) : (
                                <>
                                    <li className="nav-btn" id="register-btn">
                                        <Link to="/register" className="btn-link">
                                            <Button
                                                buttonStyle="btn--outline"
                                                buttonSize={button ? undefined : "btn--mobile"}
                                                onClick={button ? undefined : closeMobileMenu}
                                            >
                                                Sign Up
                                            </Button>
                                        </Link>
                                    </li>

                                    <li className="nav-btn">
                                        <Link to="/login" className="btn-link">
                                            <Button
                                                buttonStyle="btn--outline"
                                                buttonSize={button ? undefined : "btn--mobile"}
                                                onClick={button ? undefined : closeMobileMenu}
                                            >
                                                Log In
                                            </Button>
                                        </Link>
                                    </li>
                                </>
                            )}
                        </ul>
                    </div>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;