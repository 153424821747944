import { FaEarlybirds } from "react-icons/fa";
import React, { useState } from "react";
import { initiatePasswordReset } from "../../../api/auth"; // You'd need to create this function
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../../assets/img/realOwlLogo.svg";
import { ToastContainer, toast } from "react-toastify";
import "./ForgotPassword.css"; // Make sure to create a relevant CSS file
import {
  Container,
  Paper,
  TextField,
  Button,
  Typography,
  Box,
} from "@mui/material";
const ForgotPassword = () => {
  const [values, setValues] = useState({
    email: "",
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      await initiatePasswordReset(values); // Here's where you'd hit the reset password endpoint

      // Show success notification
      toast.success("🦉 Password reset link sent to your email!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.log(error.response.data.errors[0].msg);
      setError(error.response.data.errors[0].msg);

      setTimeout(() => {
        navigate("/login");
      }, 1500);

      // Show error notification
      toast.error(`🦉 Error: ${error.response.data.errors[0].msg}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-form" onSubmit={onSubmit}>
        <h2 className="forgot-brand-title">
          <Link to="/" className="forgot-brand-content">
            <Logo className="forgot-brand-logo" />
            <span className="forgot-brand-text">Preclinique</span>
          </Link>
        </h2>

        <div className="forgot-input-group">
          <label htmlFor="forgot-email">Email address</label>
          <TextField
            fullWidth
            label="Email Address"
            type="email"
            variant="filled"
            id="email"
            name="email"
            value={values.email}
            onChange={onChange}
            required
            margin="normal"
          />
        </div>

        {error && <p style={{ color: "red", margin: "10px 0" }}>{error}</p>}

        <button className="forgot-submit-button">Send Reset Link</button>

        <div className="forgot-login-redirect">
          Remembered your password?{" "}
          <div id="forgot-login-link">
            <Link to="/login" className="forgot-login-link">
              Login here
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
