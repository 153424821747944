import { createSlice } from "@reduxjs/toolkit";

export const resultReducer = createSlice({
  name: "result",
  initialState: {
    userId: null,
    result: [], // This will store an array of { question_id, option_id } objects.
  },
  reducers: {
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    pushResultAction: (state, action) => {
      const existingResultIndex = state.result.findIndex(
        (r) => r.question_id === action.payload.question_id
      );

      if (existingResultIndex === -1) {
        state.result.push(action.payload);
      }
    },

    resetResultAction: () => {
      return {
        userId: null,
        result: [],
      };
    },
  },
});

export const { setUserId, pushResultAction, resetResultAction } =
  resultReducer.actions;

export default resultReducer.reducer;
