import React from "react";
import "./CardSection.css";
import Icon1 from "../../assets/img/tailoredColor.svg";
import Icon2 from "../../assets/img/arrowColor.svg";
import Icon3 from "../../assets/img/performanceColor.svg";
import Lottie from "lottie-react";
import OwlWaving from "../../assets/lottieAnimations/Owl-Waving.json";

const CardSection = () => {
  return (
    <section className="services-container" id="services">
      <h1 className="services-h1">What do we have in store?</h1>
      {/* <Lottie
        className="home__owl-animation"
        animationData={OwlWaving}
        loop
        autoplay
      /> */}
      <div className="services-wrapper">
        <div className="services-card">
          <div className="card-image">
            <img className="services-icon" src={Icon1} alt="Service 1" />
          </div>
          <div className="card-text">
            <h2 className="services-h2">Tailored Question Bank </h2>
            <ul className="services-p">
              <li>Our priority and focus is you</li>
              <li>Question banks that align with curriculums of medical and dental schools</li>
              <li>The hard works all done, all you need to do is learn </li>
            </ul>
          </div>
        </div>
        <div className="services-card">
          <div className="card-image">
            <img className="services-icon" src={Icon2} alt="Service 2" />
          </div>

          <div className="card-text">
            <h2 className="services-h2">Personalised Learning </h2>
            <ul className="services-p">
              <li>Filter by year, semester, module</li>
              <li>Specific algorithms to help target weaker areas</li>
              <li>Review sections for reflection and understanding </li>
            </ul>
          </div>
        </div>
        <div className="services-card">
          <div className="card-image">
            <img className="services-icon" src={Icon3} alt="Service 3" />
          </div>

          <div className="card-text">
            <h2 className="services-h2">Performance Analytics </h2>
            <ul className="services-p">
              <li>Thorough breakdowns of strengths & weaknesses</li>
              <li>Refine your study & take your knowledge to new heights</li>
              <li>
                See how you're getting along compared to your university peers
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CardSection;
