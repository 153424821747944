import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";

function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate(); // Create navigate function instance

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // Navigate to Home Page
  const handleGoToHomePage = () => {
    handleMenuClose();
    navigate("/dashboard/homepage");
  };

  // Navigate to My Account (I'm assuming it's the settings page)
  const handleGoToMyAccount = () => {
    handleMenuClose();
    navigate("/dashboard/settings");
    };

  const menuId = "primary-search-account-menu";
  return (
    <>
      <IconButton
        size="large"
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="black"
      >
        <AccountCircle color="black" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleGoToHomePage}>Home Page</MenuItem>
        <MenuItem onClick={handleGoToMyAccount}>My account</MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
