import * as Action from "../redux/reducers/result_reducer";

/**
 * Push a new answer.
 * @param {Object} result - The result object.
 * @param {number} result.question_id - The ID of the question.
 * @param {number} result.option_id - The ID of the selected option.
 */
export const PushAnswer = (result) => async (dispatch) => {
  try {
    await dispatch(Action.pushResultAction(result));
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update an existing answer.
 * @param {Object} result - The result object.
 * @param {number} result.question_id - The ID of the question.
 * @param {number} result.option_id - The ID of the selected option.
 */
