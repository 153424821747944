import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import QuizIcon from "@mui/icons-material/Quiz";
import GradingIcon from "@mui/icons-material/Grading";
import LogoutIcon from "@mui/icons-material/Logout";
import BarChartIcon from "@mui/icons-material/BarChart";
import { FaEarlybirds } from "react-icons/fa";
import { ReactComponent as Logo } from "../../../../assets/img/realOwlLogo.svg";
import { useNavigate } from "react-router-dom";
import { onLogout } from "../../../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { unauthenticateUser } from "../../../../redux/slices/authSlice";
import { ToastContainer, toast } from "react-toastify";
import SearchBar from "./SearchBar";
import UserMenu from "./UserMenu";
import Grading from "@mui/icons-material/Grading";
const drawerWidth = 290;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: "80px", // fixed width for closed sidebar
  [theme.breakpoints.up("sm")]: {
    width: "80px", // consistent fixed width for larger screens
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const [isHovered, setIsHovered] = React.useState(false);
  const university_name = useSelector((state) => state.auth.universityName);

  const logout = async () => {
    try {
      await onLogout();

      dispatch(unauthenticateUser());
      localStorage.removeItem("isAuth");
      localStorage.removeItem("userId");

      // Display toast notification for successful logout
      toast.success("🦉 Logged out successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } catch (error) {
      console.log(error.response);
      toast.error("Encountered an error logging you out!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ backgroundColor: "#fff" }}>
        <Toolbar>
          <IconButton
            color="rgb(112,112,112)"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              marginLeft: -0.6,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />{" "}
          {/* Pushes The search bar and user menu to the right hand side */}
          <SearchBar />
          <UserMenu />
        </Toolbar>
      </AppBar>

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <Box display="flex" alignItems="center">
            <Logo className="logo-image" height="50" width="50" />

            <Box alignSelf="center">
              <Typography
                variant="h5"
                noWrap
                sx={{ marginLeft: 1, marginTop: 0.25, fontWeight: "bold" }}
                          >
                              <span className="brand-name">
                                  Preclinique
                              </span>
              </Typography>
            </Box>
          </Box>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/homepage");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0,
                }}
              >
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Dashboard"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/question-bank");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0, // Reset margin right when sidebar is closed
                }}
              >
                <AccountBalanceIcon />
              </ListItemIcon>
              <ListItemText
                primary="Question Bank"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {/* <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/mock-tests");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0,
                }}
              >
                <QuizIcon />
              </ListItemIcon>
              <ListItemText
                primary="Mock Tests"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem> */}

          {university_name === "Imperial College London" && (
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              onClick={() => {
                navigate("/dashboard/saq");
              }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "flex-start" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                    justifyContent: "center", // Center the HomeIcon
                    marginRight: open ? 3 : 0,
                  }}
                >
                  <QuizIcon />
                </ListItemIcon>
                <ListItemText primary="SAQ" sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          )}

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/review");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0,
                }}
              >
                <GradingIcon />
              </ListItemIcon>
              <ListItemText primary="Review" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/analytics");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0,
                }}
              >
                <BarChartIcon />
              </ListItemIcon>
              <ListItemText
                primary="Analytics"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
              navigate("/dashboard/settings");
            }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "flex-start" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                  justifyContent: "center", // Center the HomeIcon
                  marginRight: open ? 3 : 0,
                }}
              >
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>

          <Divider />
          <List>
            <Button
              variant="contained"
              color="primary"
              onClick={logout}
              sx={{
                fontWeight: "bold",
                display: "block",
                width: "80%", // Adjust the width as desired
                margin: "0 auto", // Center the button horizontally
                marginTop: "2rem",
                padding: "0.75rem 1rem",
                color: "#ffff",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "1rem",
                transition: "background-color 0.2s ease-in-out",
                textAlign: "center", // Center the text inside the button
                "&:hover": {
                  backgroundColor: "#2167ca", // Background color on hover
                },
              }}
            >
              {open ? (
                <ListItemText
                  primary="Logout"
                  primaryTypographyProps={{
                    style: { fontSize: "1rem", fontWeight: "bold" },
                  }}
                />
              ) : (
                <ListItemIcon
                  style={{
                    minWidth: open ? "initial" : "100%", // Only occupy full width when closed
                    justifyContent: "center", // Center the icon
                    marginRight: open ? 3 : 0,
                  }}
                >
                  <LogoutIcon style={{ color: "#fff" }} />
                </ListItemIcon>
              )}
            </Button>
          </List>
        </List>
      </Drawer>
    </Box>
  );
}
