import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./TestimonialSection.css";
import "./CardSection.css";

import owlSpeechBubble3 from "../../assets/img/testomonial.svg";
import speech from "../../assets/img/speech.svg";

import Switch from "@mui/material/Switch";

const TestimonialSection = () => {
  const [showCards, setShowCards] = useState(false);
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const testimonials = [
    {
      name: "Jack",
      content:
        "The variety of questions with well-explained answers was really good. I felt thoroughly prepared for my exam after this session. Great job in covering a wide range of topics!",
      university: "Imperial College London",
      year: "1st Year, Medical Student",
    },
    {
      name: "Chinedu",
      content:
        "I found the winter webinar series very helpful, especially the corrections with explanations for the wrong answers. It gave me a clearer understanding of areas I need to focus on.",
      university: "University of Manchester",
      year: "1st Year, Dental Student",
    },
    {
      name: "Emily",
      content:
        "Better timings to answer the questions was a significant improvement. It helped in managing exam stress and time pressure. Also, the VSAQ segment was really insightful.",
      university: "Imperial College London",
      year: "2nd Year, Medical Student",
    },

    {
      name: "Haruto",
      content:
        "I love that you guys went through incorrect explanations for each option in the webinar series. It's one thing to know the right answer, but understanding why other options are incorrect deepens our learning.",
      university: "University of Manchester",
      year: "2nd Year, Dental Student",
    },

    {
      name: "Kemi",
      content:
        "A good overview and recap, with realistic questions they would ask in the POM mock. The questions were perfect, and I found it really good that the incorrect answers were worked through thoroughly.",
      university: "Imperial College London",
      year: "2nd Year, Medical Student",
    },

    {
      name: "Sophia",
      content:
        "Everything was very well explained, as always, thank you! More sessions towards exam season would be super helpful. The depth and breadth of material covered were just right.",
      university: "Imperial College London",
      year: "1st Year, Medical Student",
    },

    {
      name: "Hassan",
      content:
        "Coverage of interesting and harder questions was great! A lot of questions challenged us and also highlighted how some more niche information is needed.",
      university: "University of Manchester",
      year: "1st Year, Dental Student",
    },

    {
      name: "Wei",
      content:
        "The explanations were really helpful :) I appreciated the comprehensive and detailed approach to each topic. It made complex concepts much easier to understand.",
      university: "University of Manchester",
      year: "2nd Year, Dental Student",
    },
  ];

  const changeTestimonial = (newIndex) => {
    setFade(false);
    setTimeout(() => {
      setCurrentTestimonialIndex(newIndex);
      setFade(true);
    }, 500); // This timeout should match your CSS fade effect duration
  };

  const nextTestimonial = () => {
    const newIndex =
      currentTestimonialIndex === testimonials.length - 1
        ? 0
        : currentTestimonialIndex + 1;
    changeTestimonial(newIndex);
  };

  const previousTestimonial = () => {
    const newIndex =
      currentTestimonialIndex === 0
        ? testimonials.length - 1
        : currentTestimonialIndex - 1;
    changeTestimonial(newIndex);
  };

  return (
    <section className="testimonial-container" id="testimonials">
      <h1 className="testimonial-h1">Student Testimonials</h1>

      <Switch checked={showCards} onChange={() => setShowCards(!showCards)} />

      {showCards ? (
        <div className={`testimonial-card ${fade ? "fade-in" : "fade-out"}`}>
          <div className="testimonial-text">
            <h2 className="testimonial-h2">
              {testimonials[currentTestimonialIndex].name}
            </h2>
            <h2 className="says">Says:</h2>
            <p className="testimonial-p">
              {testimonials[currentTestimonialIndex].content}
            </p>
            <p className="testimonial-university">
              {testimonials[currentTestimonialIndex].university}
            </p>
            <p className="testimonial-year">
              {testimonials[currentTestimonialIndex].year}
            </p>
          </div>
          <div className="testimonial-navigation">
            <button onClick={previousTestimonial}>&larr;</button>
            <button onClick={nextTestimonial}>&rarr;</button>
          </div>
        </div>
      ) : (
        <img
          src={owlSpeechBubble3}
          alt="Owl Speech Bubble"
          className="owl-speech-bubble"
        />
      )}
    </section>
  );
};

export default TestimonialSection;
