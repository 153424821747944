export const homeObjOne = {
  isFirstSection: true,
  columnDirection: false,
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Beyond Questions",
  subHeadline: "Your Voice, Our Priority.",
  description:
    "University Tailored Question Banks For Your Pre-Clinical Success",
  buttonLabel: "Get Started",
  imgStart: "",
  img: "images/newOwlMascotHorizontal2.svg",
  alt: "Bird",
};

export const homeObjThree = {
  isFirstSection: true,
  columnDirection: false,
  lightBg: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Questions Crafted by Top Pre-Clinical Students",
  description:
    "Our questions are meticulously crafted by future-doctors who excelled at the pinnacle of their pre-clinical years. \nTailored for each university, every question bank is designed by students from their specific institution, ensuring relevance and accuracy.",
  buttonLabel: "Dive In",
  imgStart: "start",
  img: "images/doctor.svg",
  alt: "doctor",
};
