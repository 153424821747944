// Determines the number of valid attempts
export function attemps_Number(result) {
  return result.filter((r) => r && r.option_id !== undefined).length;
}

// Determines the points earned
// Determines the points earned based on attempts
export function earnPoints_Number(result, answers, point) {
  const attempts = result.filter((r) => r.option_id !== undefined).length;
  const correctAnswers = result
    .map((element) => {
      const correctAnswer = answers.find(
        (a) => a.question_id === element.question_id
      );
      return (
        correctAnswer && correctAnswer.correct_option_id === element.option_id
      );
    })
    .filter((isCorrect) => isCorrect);

  return {
    points: correctAnswers
      .map(() => point)
      .reduce((prev, curr) => prev + curr, 0),
    correctCount: correctAnswers.length,
    attempts: attempts,
  };
}

// No change here; it determines if earned points exceed half of total points
export function flagResult(totalPoints, earnPoints) {
  return (totalPoints * 50) / 100 < earnPoints;
}
