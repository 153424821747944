import "./CommentsSection.css";
import React, { useEffect, useState, useCallback } from "react";
import {
    getCommentsByQuestionId,
    addComment,
    likeComment,
} from "../../../../../api/auth";
import { FaReply, FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import CircularProgress from "@mui/material/CircularProgress";

function CommentsSection({ questionId, userId }) {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyContent, setReplyContent] = useState("");
    const [isPosting, setIsPosting] = useState(false);
    const [isReplying, setIsReplying] = useState(false);

    useEffect(() => {
        fetchComments();
    }, [questionId]);

    const fetchComments = async () => {
        try {
            const response = await getCommentsByQuestionId(questionId, userId); // Pass the userId
            setComments(response.data.comments);
        } catch (error) {
            console.error("Failed to fetch comments:", error);
        }
    };

    const handleAddComment = async () => {
        setIsPosting(true);
        try {
            const response = await addComment({
                question_id: questionId,
                user_id: userId,
                comment_text: newComment,
                parent_comment_id: null,
            });
            setComments([...comments, response.data.comment]);
            setNewComment("");
        } catch (error) {
            console.error("Failed to add comment:", error);
        }

        // Artificial delay of 1 second to show spinner
        setTimeout(() => {
            setIsPosting(false);
        }, 1000);
    };

    const handleReplyAddComment = async (commentId) => {
        setIsReplying(true);
        try {
            const response = await addComment({
                question_id: questionId,
                user_id: userId,
                comment_text: replyContent,
                parent_comment_id: commentId,
            });
            setComments([...comments, response.data.comment]);
            setReplyingTo(null);
            setReplyContent("");
        } catch (error) {
            console.error("Failed to add reply:", error);
        }

        // Artificial delay of 1 second to show spinner
        setTimeout(() => {
            setIsReplying(false);
        }, 1000);
    };

    const handleReplyClick = (commentId) => {
        setReplyingTo(commentId);
        setReplyContent("");
    };

    const handleLikeDislike = useCallback(
        async (commentId, isLike) => {
            const comment = comments.find((c) => c.comment_id === commentId);
            const alreadyLiked = comment.user_liked;
            const alreadyDisliked = comment.user_disliked;

            let newLikes = comment.likes_count;
            let newDislikes = comment.dislikes_count;

            if (isLike) {
                if (alreadyLiked) {
                    newLikes--;
                    comment.user_liked = false;
                } else {
                    if (alreadyDisliked) {
                        newDislikes--;
                        comment.user_disliked = false;
                    }
                    newLikes++;
                    comment.user_liked = true;
                }
            } else {
                if (alreadyDisliked) {
                    newDislikes--;
                    comment.user_disliked = false;
                } else {
                    if (alreadyLiked) {
                        newLikes--;
                        comment.user_liked = false;
                    }
                    newDislikes++;
                    comment.user_disliked = true;
                }
            }

            // Update the comment with new like/dislike counts
            const updatedComments = comments.map((c) =>
                c.comment_id === commentId
                    ? { ...comment, likes_count: newLikes, dislikes_count: newDislikes }
                    : c
            );

            setComments(updatedComments);

            try {
                const response = await likeComment({
                    user_id: userId,
                    comment_id: commentId,
                    is_like: isLike,
                });

                if (!response.data.success) {
                    console.error("Failed to update like/dislike state");
                }
            } catch (error) {
                console.error("Failed to like/dislike comment:", error);
            }

            // Fetch comments again to ensure the latest data
            await fetchComments(); // Make sure the updated counts and states are refreshed
        },
        [comments, userId]
    );

    // Memoize the rendering of comments to avoid excessive re-renders
    const renderComments = useCallback(
        (parentId = null) => {
            return comments
                .filter((comment) => comment.parent_comment_id === parentId)
                .sort((a, b) => b.likes_count - a.likes_count) // Sort by most liked comments
                .map((comment) => (
                    <div key={comment.comment_id} className="comment">
                        <strong>{comment.user_name}</strong>
                        <span className="comment-timestamp">
                            {new Date(comment.created_at).toLocaleString()}
                        </span>
                        <p>{comment.comment_text}</p>

                        {/* Like/Dislike Buttons */}
                        <div className="like-dislike-buttons">
                            <button
                                className={comment.user_liked ? "liked" : ""}
                                onClick={() => handleLikeDislike(comment.comment_id, true)}
                            >
                                <FaThumbsUp className={comment.user_liked ? "liked" : ""} />{" "}
                                {comment.likes_count}
                            </button>
                            <button
                                className={comment.user_disliked ? "disliked" : ""}
                                onClick={() => handleLikeDislike(comment.comment_id, false)}
                            >
                                <FaThumbsDown
                                    className={comment.user_disliked ? "disliked" : ""}
                                />{" "}
                                {comment.dislikes_count}
                            </button>
                        </div>

                        <button
                            className="reply-button"
                            onClick={() => handleReplyClick(comment.comment_id)}
                        >
                            <FaReply className="reply-icon" /> Reply
                        </button>

                        {replyingTo === comment.comment_id && (
                            <div className="reply-input-container">
                                <textarea
                                    value={replyContent}
                                    onChange={(e) => setReplyContent(e.target.value)}
                                    placeholder="Write your reply..."
                                ></textarea>
                                <div className="reply-actions">
                                    <button
                                        onClick={() => handleReplyAddComment(comment.comment_id)}
                                        disabled={isReplying}
                                    >
                                        {isReplying ? (
                                            <CircularProgress size={20} style={{ color: "white" }} />
                                        ) : (
                                            "Post Reply"
                                        )}
                                    </button>
                                    <button onClick={() => setReplyingTo(null)}>Cancel</button>
                                </div>
                            </div>
                        )}
                        <div className="replies">{renderComments(comment.comment_id)}</div>
                    </div>
                ));
        },
        [comments, handleLikeDislike, replyingTo, replyContent, isReplying]
    );

    return (
        <div className="comments-section">
            <h4>
                {comments.length} {comments.length === 1 ? "Comment" : "Comments"}
            </h4>
            <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                placeholder="Add a comment..."
            ></textarea>
            <button
                className="post-button"
                onClick={handleAddComment}
                disabled={isPosting}
            >
                {isPosting ? (
                    <CircularProgress size={20} style={{ color: "white" }} />
                ) : (
                    "Post"
                )}
            </button>
            {renderComments()}
        </div>
    );
}

export default CommentsSection;