import React, { useState } from "react";
import "./WhatsNew.css";
import Switch from "@mui/material/Switch";
import UpdatesImage from "../../../assets/img/WhatsNew.svg";

const WhatsNew = () => {
    const [showWebAppFeatures, setShowWebAppFeatures] = useState(true);
    const [fadeClass, setFadeClass] = useState("fade-in");

    const toggleShowFeatures = () => {
        setFadeClass("fade-out");
        setTimeout(() => {
            setShowWebAppFeatures(!showWebAppFeatures);
            setFadeClass("fade-in");
        }, 500); // Duration of the fade-out animation
    };

    return (
        <section className="whats-new section" id="whats-new">
            <h2 className="section__title">What's New?</h2>
            <span className="section__subtitle">
                Latest Updates and Upcoming Features
            </span>

            <img src={UpdatesImage} alt="Updates" className="updates-image" />

            <div className="toggle-container">
                <Switch
                    checked={showWebAppFeatures}
                    onChange={toggleShowFeatures}
                    color="primary"
                />
            </div>

            <div className={`feature-span ${fadeClass}`}>
                {showWebAppFeatures ? (
                    <span>University Updates</span>
                ) : (
                    <span>Web App Features</span>
                )}
            </div>

            <div className={`whats-new__container container grid ${fadeClass}`}>
                {showWebAppFeatures ? (
                    <>
                        {/* Web App Features */}
                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                Upcoming: Nottingham Question Bank
                            </h3>
                            <span className="whats-new__date">Expected: Sep 2024</span>
                            <p className="whats-new__description">
                                We are working on a comprehensive question bank for
                                Nottingham (Year 1 & Year 2). Stay tuned for more
                                details!
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming: Leicester Question Bank
                            </h3>
                            <span className="whats-new__date">Expected: Oct 2024</span>
                            <p className="whats-new__description">
                                We are working on a comprehensive question bank for
                                Nottingham (Year 1 & Year 2). Stay tuned for more
                                details!
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title"> Upcoming: Year 2 Imperial</h3>
                            <span className="whats-new__date">Expected: Oct 2024</span>
                            <p className="whats-new__description">
                                Excited to announce that we are currently developing questions
                                for Year 2 Imperial!
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming: Aston Question Bank
                            </h3>
                            <span className="whats-new__date">Expected: Oct 2024</span>
                            <p className="whats-new__description">
                                We are working on a comprehensive question bank for Aston
                                (Year 1 & Year 2). Stay tuned for more details!
                            </p>
                        </div>
                    </>
                ) : (
                    <>
                        {/* University Updates */}
                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                Upcoming Feature: Report Question
                            </h3>
                            <span className="whats-new__date">Expected: Aug 2024</span>
                            <p className="whats-new__description">
                                Users will be able report any issues with questions, such as
                                incorrect answers or poor quality.
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming Feature: Comment Section
                            </h3>
                            <span className="whats-new__date">Expected: Sep 2024</span>
                            <p className="whats-new__description">
                                Each question will soon have a comment section where users can
                                interact and reply to help each other.
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming Feature: Mock Tests
                            </h3>
                            <span className="whats-new__date">Expected: 2025</span>
                            <p className="whats-new__description">
                                Mock tests to simulate real test conditions for users, to allow
                                them to be more confident in their real life exams...
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming Feature: Notes Section
                            </h3>
                            <span className="whats-new__date">Expected: Nov 2024</span>
                            <p className="whats-new__description">
                                A dedicated notes section allowing users to store and access
                                their personal notes for each question, providing easy
                                reference!
                            </p>
                        </div>

                        <div className="whats-new__content">
                            <h3 className="whats-new__title">
                                {" "}
                                Upcoming Feature: Connect and Compete with Friends
                            </h3>
                            <span className="whats-new__date">Expected: TBC</span>
                            <p className="whats-new__description">
                                Users will soon have the ability to add each other as friends
                                and engage in interactive game battles, enhancing the learning
                                experience through friendly competition.
                            </p>
                        </div>
                    </>
                )}
            </div>
        </section>
    );
};

export default WhatsNew;