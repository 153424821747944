import { FaEarlybirds } from "react-icons/fa";
import React, { useState, useEffect } from "react";
import { confirmEmail } from "../../../api/auth";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./ConfirmEmail.css";

const ConfirmEmail = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Extract confirmation_token from URL
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("confirmation_token");
    //Function to confirm email
    const confirmUserEmail = async (token) => {
      try {
        await confirmEmail(token);
        setMessage(
          "Email confirmed successfully! Redirecting to you to the login page. "
        );
        toast.success(
          "🦉 Email confirmed successfully! Redirecting to you to the login page.",
          {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          navigate("/login");
        }, 8000);
      } catch (error) {
        console.log(error);
        setMessage("An Error occured while trying to confirm the email.");
        toast.error(
          "🦉 Error: An Error occured while trying to confirm the email",
          {
            position: "top-right",
            autoClose: 8000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
        setTimeout(() => {
          navigate("/login");
        }, 8000);
      }
    };

    //Call the function to confirm email
    confirmUserEmail(token);
  }, []);
  return (
    <div className="confirm-email-container">
      <div className="confirm-email-content">
        <div className="confirm-email-brand-title">
          <FaEarlybirds className="confirm-brand-logo" />

          <div className="confirm-brand-text">Ready to Ace your Exams?</div>
        </div>

        <h2 className="confirm-email-message">{message}</h2>
      </div>
    </div>
  );
};

export default ConfirmEmail;
