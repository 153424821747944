import React from "react";
import CookieConsent from "react-cookie-consent";
import Cookies from "js-cookie";

const CookieManager = () => {
    const handleAcceptCookies = () => {
        // Set a cookie to track consent
        Cookies.set("user-consent", "accepted", { expires: 365 });
        // Logic to enable third-party cookies
        enableThirdPartyCookies(true);
    };

    const handleDeclineCookies = () => {
        // Remove or avoid setting non-essential cookies
        Cookies.remove("user-consent");
        // Logic to disable third-party cookies
        enableThirdPartyCookies(false);
    };

    const enableThirdPartyCookies = (enable) => {
        if (enable) {
            // Load third-party scripts or set cookies
            console.log("Third-party cookies are enabled");
        } else {
            // Disable or remove third-party cookies
            console.log("Third-party cookies are disabled");
        }
    };

    return (
        <div>
            <CookieConsent
                onAccept={handleAcceptCookies}
                onDecline={handleDeclineCookies}
                buttonText="Accept"
                declineButtonText="Reject"
                enableDeclineButton
                style={{ background: "#2B373B" }}
                buttonStyle={{
                    backgroundColor: "rgb(28, 120, 201)",
                    color: "#ffffff",
                    fontSize: "13px",
                    width: "60px",
                }}
                declineButtonStyle={{
                    backgroundColor: "rgb(28, 120, 201)",
                    color: "#ffffff",
                    fontSize: "13px",
                    width: "60px",
                    marginRight: "0px",
                }}
            >
                We utilize cookies to improve your browsing experience. By selecting
                'Accept,' you consent to storing cookies on your device. For more
                details, please refer to our{" "}
                <a href="/privacy-policy" style={{ color: "#00bfff" }}>
                    Privacy Policy
                </a>
                .
            </CookieConsent>
        </div>
    );
};

export default CookieManager;