import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../../DashboardPages/DashboardPagesStyles/DashboardQuestionsResults.css";

import TotalQuestionsSVG from "../../../../../../assets/img/checklist.svg";
import TotalAttemptsSVG from "../../../../../../assets/img/arrows.svg";
import CorrectAnswersSVG from "../../../../../../assets/img/check.svg";
import ScorePercentageSVG from "../../../../../../assets/img/percent.svg";
import CircularProgress from "@mui/material/CircularProgress";

import { Container, Box, Paper, Grid, Typography } from "@mui/material";
import {
  attemps_Number,
  earnPoints_Number,
  flagResult,
} from "../../../../../../helper/helper";

import { useDispatch, useSelector } from "react-redux";

/**import actions */
import { resetAllAction } from "../../../../../../redux/reducers/question_reducer";
import { resetResultAction } from "../../../../../../redux/reducers/result_reducer";
import { submitTest, deleteSession } from "../../../../../../api/auth";

export default function Result() {
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();
  const userId = useSelector((state) => state.auth.userId);
  const universityName = useSelector((state) => state.auth.universityName);
  const classYear = useSelector((state) => state.auth.classYear);
  const course = useSelector((state) => state.auth.courseName);
  const { queue, answers } = useSelector((state) => state.questions);
  const result = useSelector((state) => state.result.result);

  const totalPoints = queue.length * 10;
  const {
    points: earnPoints,
    correctCount,
    attempts,
  } = earnPoints_Number(result, answers, 10);
  const flag = flagResult(totalPoints, earnPoints);

  const dispatch = useDispatch();

  const handleDeleteSession = async () => {
    try {
      console.log("uid", userId);
      const removalOfResumeSession = await deleteSession(userId);
      if (removalOfResumeSession.success) {
        console.log("removed resume session");
      } else {
        console.log("error removing resume session");
      }
    } catch (error) {
      // Check if the error is a 404 status code
      if (error.response && error.response.status === 404) {
        console.log("Session not found, but treated as success");
        return; // Return here to exit the function with a success message
      }
      // For other errors, log the error
      console.error("Error removing resume session:", error);
    }
  };

  // Pass necessary data as arguments to handleSubmitTest
  const handleSubmitTest = async (
    userId,
    universityName,
    classYear,
    course
  ) => {
    setIsSaving(true); // Start loading

    // Artificial delay
    setTimeout(async () => {
      // Calculate the score as a percentage and round it
      const score =
        attempts > 0 ? Math.round((earnPoints / (attempts * 10)) * 100) : 0;

      try {
        const response = await submitTest(
          userId,
          universityName,
          classYear,
          score,
          course
        );

        if (response.data.success) {
          console.log("Test submitted successfully");
          // Handle successful submission
        } else {
          console.error("Error submitting test:", response.data.error);
          // Handle error case
        }
      } catch (error) {
        console.error("Error submitting test:", error);
        // Handle error case
      }

      onRestart();
      navigate("/dashboard/question-bank");
      setIsSaving(false); // End loading
    }, 2000); // Delay in milliseconds
  };

  function onRestart() {
    dispatch(resetAllAction());
    dispatch(resetResultAction());
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "rgb(249, 249, 249)",
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
        p: 2,
      }}
    >
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Paper
          elevation={1}
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",

            padding: "30px",
            height: "80vh",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Session Result
            </Typography>
            <button
              onClick={() => {
                handleSubmitTest(userId, universityName, classYear, course);
                handleDeleteSession();
              }}
              className="btn-resultSession"
              disabled={isSaving}
            >
              {isSaving ? <CircularProgress size={24} /> : "Save Progress"}
            </button>
          </div>
          <Grid
            container
            spacing={2}
            sx={{
              display: "flex",
              alignItems: "stretch",
              flexGrow: 1,
              minHeight: 0,
            }}
          >
            {/* Grid items */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                height: "358px",
                // Take full height of the parent grid item
                // Other styles...
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Center content vertically
                  height: "328px",
                  // Take full height of the parent grid item
                  // Other styles...
                }}
              >
                <div className="result-item">
                  <div className="result-text" style={{ marginRight: "20px" }}>
                    <Typography
                      className="result-label"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      Total Questions:{" "}
                    </Typography>
                    <Typography
                      className="result-value"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        marginLeft: "5px",
                      }}
                    >
                      {queue.length || 0}
                    </Typography>
                  </div>

                  <img
                    src={TotalQuestionsSVG}
                    alt="Total Questions"
                    width="200"
                    height="200"
                  />
                </div>
              </Paper>
            </Grid>

            {/* Total Questions Attempted */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                height: "358px", // Take full height of the parent grid item
                // Other styles...
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Center content vertically
                  height: "328px", // Take full height of the parent grid item
                  // Other styles...
                }}
              >
                <div className="result-item">
                  <div className="result-text">
                    <Typography
                      className="result-label"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      Total Attempts:
                    </Typography>
                    <Typography
                      className="result-value"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        marginLeft: "5px",
                      }}
                    >
                      {attempts}
                    </Typography>
                  </div>
                  <img
                    src={TotalAttemptsSVG}
                    alt="Total Attempts"
                    width="200"
                    height="200"
                  />
                </div>
              </Paper>
            </Grid>

            {/* Correct Answers */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                height: "358px", // Take full height of the parent grid item
                // Other styles...
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Center content vertically
                  height: "328px", // Take full height of the parent grid item
                  // Other styles...
                }}
              >
                <div className="result-item">
                  <div className="result-text">
                    <Typography
                      className="result-label"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      Correct Answers:
                    </Typography>

                    <Typography
                      className="result-value"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",

                        marginLeft: "5px",
                      }}
                    >
                      {correctCount}/{attempts}
                    </Typography>
                  </div>
                  <img
                    src={CorrectAnswersSVG}
                    alt="Correct Answers"
                    width="200"
                    height="200"
                  />
                </div>
              </Paper>
            </Grid>

            {/* Score Percentage */}
            <Grid
              item
              xs={12}
              sm={12}
              sx={{
                height: "358px", // Take full height of the parent grid item
                // Other styles...
              }}
            >
              <Paper
                elevation={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center", // Center content vertically
                  height: "328px", // Take full height of the parent grid item
                  // Other styles...
                }}
              >
                <div className="result-item">
                  <div className="result-text" style={{ marginLeft: "-30px" }}>
                    <Typography
                      className="result-label"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                      }}
                    >
                      Percentage Value:
                    </Typography>
                    <Typography
                      className="result-value"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                        marginLeft: "5px",
                      }}
                    >
                      {attempts > 0
                        ? ((earnPoints / (attempts * 10)) * 100).toFixed(1)
                        : "0"}
                    </Typography>
                  </div>

                  <img
                    src={ScorePercentageSVG}
                    alt="Correct Answers"
                    width="200"
                    height="200"
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
          {/* <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
            <button
              onClick={() => {
                handleSubmitTest(userId, universityName, classYear, course);
                handleDeleteSession();
              }}
              className="btn"
              disabled={isSaving}
            >
              {isSaving ? <CircularProgress size={24} /> : "Save Progress"}
            </button>
          </Box> */}
        </Paper>
      </Container>
    </Box>
  );
}
