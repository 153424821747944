import { createSlice } from "@reduxjs/toolkit";

export const selectedQuestionsSlice = createSlice({
    name: "selectedQuestions",
    initialState: {
        questions: [], // Array to store objects containing question IDs and types
    },
    reducers: {
        setQuestions: (state, action) => {
            state.questions = action.payload;
        },
        // Add any other reducers you might need, like adding or removing a question
    },
});

export const { setQuestions } = selectedQuestionsSlice.actions;

export default selectedQuestionsSlice.reducer;
