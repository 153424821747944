import React, { useState, useEffect } from "react";
import {
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ConfirmationCard from "./ConfirmationCard";
import Dialog from "@mui/material/Dialog";
import { useNavigate } from "react-router-dom";

import { getSubcategoriesByMainCategoryWithQuestionCount } from "../../../../../../api/auth";
import { saveUserProgress } from "../../../../../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSubcategories } from "../../../../../../redux/slices/selectedSubcategoriesSlice";
import {
  resetAllAction,
  setTraceToZero,
} from "../../../../../../redux/reducers/question_reducer";
import { resetResultAction } from "../../../../../../redux/reducers/result_reducer";

function MainCategoryList({ mainCategories }) {
  function onRestart() {
    dispatch(resetResultAction());
  }

  const userId = useSelector((state) => state.auth.userId);
  console.log(userId);

  const dispatch = useDispatch();
  const [subCategories, setSubCategories] = useState({});
  const [loadingSubCategories, setLoadingSubCategories] = useState({});

  const [checkedMainCategories, setCheckedMainCategories] = useState(
    mainCategories.reduce((acc, category) => {
      acc[category.cat_id] = false;
      return acc;
    }, {})
  );
  const [checkedSubCategories, setCheckedSubCategories] = useState({});
  const [checkedAllCategories, setCheckedAllCategories] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    console.log(userId);
    // Fetch subcategories for all main categories when component mounts
    mainCategories.forEach(async (category) => {
      if (!subCategories[category.cat_id]) {
        try {
          const response =
            await getSubcategoriesByMainCategoryWithQuestionCount(
              category.cat_id,
              userId
            );
          setSubCategories((prev) => ({
            ...prev,
            [category.cat_id]: response.data.subcategories,
          }));
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      }
    });
  }, [mainCategories, userId]);

  const handleMainCategoryCheckboxChange = (event, cat_id) => {
    event.stopPropagation();

    const isChecked = !checkedMainCategories[cat_id];
    setCheckedMainCategories((prev) => ({
      ...prev,
      [cat_id]: isChecked,
    }));

    // If subcategories are already fetched, directly update the checkboxes
    if (subCategories[cat_id]) {
      const updatedSubcategories = subCategories[cat_id].reduce(
        (acc, subCategory) => {
          acc[subCategory.subcat_id] = isChecked;
          return acc;
        },
        {}
      );

      setCheckedSubCategories((prev) => ({
        ...prev,
        ...updatedSubcategories,
      }));
    } else {
      // Fetch the subcategories and then update the checkboxes
      setLoadingSubCategories((prev) => ({ ...prev, [cat_id]: true }));
      getSubcategoriesByMainCategoryWithQuestionCount(cat_id)
        .then((response) => {
          const fetchedSubcategories = response.data.subcategories;
          setSubCategories((prev) => ({
            ...prev,
            [cat_id]: fetchedSubcategories,
          }));

          const updatedSubcategories = fetchedSubcategories.reduce(
            (acc, subCategory) => {
              acc[subCategory.subcat_id] = isChecked;
              return acc;
            },
            {}
          );

          setCheckedSubCategories((prev) => ({
            ...prev,
            ...updatedSubcategories,
          }));
        })
        .catch((error) => {
          console.error("Error fetching subcategories:", error);
        })
        .finally(() => {
          setLoadingSubCategories((prev) => ({ ...prev, [cat_id]: false }));
        });
    }

    // Only call updateAllCheckboxStatus if you're checking the last unchecked category
    // or you're unchecking the only checked category
    const totalChecked = Object.values(checkedMainCategories).filter(
      Boolean
    ).length;
    if (isChecked && totalChecked === mainCategories.length - 1) {
      updateAllCheckboxStatus();
    } else if (!isChecked && totalChecked === 1) {
      setCheckedAllCategories(false);
    }
  };

  const handleSubCategoryCheckboxChange = (subCategoryId) => {
    setCheckedSubCategories((prev) => ({
      ...prev,
      [subCategoryId]: !prev[subCategoryId],
    }));
    updateAllCheckboxStatus();
  };

  const updateAllCheckboxStatus = () => {
    const areAllMainCategoriesChecked = Object.values(
      checkedMainCategories
    ).every(Boolean);
    setCheckedAllCategories(areAllMainCategoriesChecked);
  };

  const handleAllCategoriesCheckboxChange = () => {
    const isChecked = !checkedAllCategories;

    // Update the "All" checkbox
    setCheckedAllCategories(isChecked);

    // Update the main and subcategories checkboxes accordingly
    mainCategories.forEach((category) => {
      setCheckedMainCategories((prev) => ({
        ...prev,
        [category.cat_id]: isChecked,
      }));

      if (subCategories[category.cat_id]) {
        const updatedSubcategories = subCategories[category.cat_id].reduce(
          (acc, subCategory) => {
            acc[subCategory.subcat_id] = isChecked;
            return acc;
          },
          {}
        );

        setCheckedSubCategories((prev) => ({
          ...prev,
          ...updatedSubcategories,
        }));
      }
    });
  };

  const handleAccordionExpand = async (categoryId) => {
    if (!subCategories[categoryId]) {
      setLoadingSubCategories((prev) => ({ ...prev, [categoryId]: true }));

      try {
        const response = await getSubcategoriesByMainCategoryWithQuestionCount(
          categoryId
        );
        setSubCategories((prev) => ({
          ...prev,
          [categoryId]: response.data.subcategories,
        }));
      } catch (error) {
        console.error("Error fetching subcategories:", error);
      } finally {
        setLoadingSubCategories((prev) => ({ ...prev, [categoryId]: false }));
      }
    }
  };

  // function will be triggered when the user clicks the button to start the test
  const handleStartQuestions = () => {
    setShowConfirmDialog(true);
  };

  const handleConfirmation = async () => {
    console.log("handleConfirmation called");
    const selectedSubCatIds = Object.keys(checkedSubCategories)
      .filter((subCatId) => checkedSubCategories[subCatId])
      .map((subCatId) => parseInt(subCatId)); // Convert each subCatId string to an integer
    dispatch(setSelectedSubcategories(selectedSubCatIds));

    // Reset the trace value
    dispatch(setTraceToZero());

    // Save user progress
    const subcatIds = selectedSubCatIds;
    const lastQuestionIds = []; // This should be replaced with actual logic, if available.

    try {
      await saveUserProgress(userId, subcatIds, lastQuestionIds);
      console.log("User progress saved successfully!");
    } catch (error) {
      console.error("Failed to save user progress:", error);
    }

    onRestart();

    setShowConfirmDialog(false);
    navigate("/dashboard/question-bank/questions");
  };

  const handleCancel = () => {
    setShowConfirmDialog(false);
  };

  function getSelectedCategories() {
    const categoriesWithSubs = mainCategories
      .map((mainCat) => {
        const associatedSubs = Object.entries(checkedSubCategories)
          .filter(([subCatId, isChecked]) => {
            if (isChecked) {
              const subCategoryBelongsToCurrentMain = subCategories[
                mainCat.cat_id
              ]?.some((subCat) => subCat.subcat_id.toString() === subCatId);
              return subCategoryBelongsToCurrentMain;
            }
            return false;
          })
          .map(([subCatId]) => {
            const subCat = subCategories[mainCat.cat_id]?.find(
              (sub) => sub.subcat_id.toString() === subCatId
            );
            return subCat ? subCat.subcategory_name : null;
          })
          .filter(Boolean); // remove any null values

        if (
          checkedMainCategories[mainCat.cat_id] ||
          associatedSubs.length > 0
        ) {
          return {
            main: mainCat.cat_name,
            subs: associatedSubs,
          };
        }

        return null;
      })
      .filter(Boolean); // remove any null values

    return categoriesWithSubs;
  }

  return (
    <div>
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
        Categories Selection
      </h2>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "0.7rem 1rem",
          borderBottom: "1px solid #e0e0e0",
          borderRadius: "1px",
          marginBottom: "1rem",

          borderTop: "1px solid #e0e0e0",
        }}
      >
        <Checkbox
          size="small"
          checked={checkedAllCategories}
          onChange={handleAllCategoriesCheckboxChange}
        />
        <Typography>All Categories</Typography>
      </Paper>
      {mainCategories.map((category) => (
        <Accordion
          elevation={0} // Remove the shadow
          sx={{
            borderBottom: "1px solid #e0e0e0",
            borderRadius: "1px",
            "&:not(:last-of-type)": {
              marginBottom: "1rem",
            },
          }}
          key={category.cat_id}
          onChange={() => handleAccordionExpand(category.cat_id)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              display: "flex",
              alignItems: "center",
              fontWeight: "500",
              fontSize: "1.1rem",
              fontFamily: '"Roboto", sans-serif', // Roboto is a default MUI font
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
              onClick={(event) =>
                handleMainCategoryCheckboxChange(event, category.cat_id)
              }
            >
              <Checkbox
                size="small"
                checked={!!checkedMainCategories[category.cat_id]}
              />
              <Typography>
                {category.cat_name} ({category.question_count})
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              fontSize: "1rem",
              fontFamily: '"Roboto", sans-serif',
            }}
          >
            {subCategories[category.cat_id]?.map((subCategory) => (
              <Card
                key={subCategory.subcat_id}
                style={{ marginBottom: "10px" }}
              >
                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <Checkbox
                    size="small"
                    checked={!!checkedSubCategories[subCategory.subcat_id]}
                    onChange={() =>
                      handleSubCategoryCheckboxChange(subCategory.subcat_id)
                    }
                  />
                  <Typography variant="body2" sx={{ marginLeft: "8px" }}>
                    {subCategory.subcategory_name} ({subCategory.question_count}
                    )
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
      {/* The Start Test Button */}
      <div style={{ marginTop: "20px" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartQuestions}
        >
          Start
        </Button>
      </div>

      {/* The Confirmation Dialog */}
      <Dialog open={showConfirmDialog} onClose={handleCancel}>
        <ConfirmationCard
          selectedCategories={getSelectedCategories()}
          onConfirm={handleConfirmation}
          onCancel={handleCancel}
        />
      </Dialog>
    </div>
  );
}

export default MainCategoryList;
